/*
 * Basically equal to WP's single.php - The root that decides how Post is rendered, what layout it gets.
 * Template choosing should be done based on eg. post_type, template
 */
import dynamic from 'next/dynamic';
import { Post, Article as ArticleProps, NewsPage, BlogsPage } from '@/types';

const Article = dynamic(() => import('./Article'));
const Page = dynamic(() => import('./Page'));
const FrontPage = dynamic(() => import('./FrontPage'));
const ListingsPage = dynamic(() => import('./ListingsPage'));
const PlainPage = dynamic(() => import('./PlainPage'));
const BlogListingPage = dynamic(() => import('./BlogListingPage'));
const NewsListingPage = dynamic(() => import('./NewsListingPage'));
const GuidePage = dynamic(() => import('./GuidePage'));

const Single = ({ post }: { post: Post }) => {
  if (!post) {
    return null;
  }
  if (post.template) {
    switch (post.template) {
      case 'front-page':
      case 'contact-page':
      case 'partial-front-page':
      case 'campaign-listings-page':
        return <FrontPage {...post} />;
      case 'listings-page':
        return <ListingsPage {...post} />;
      case 'guide-page':
        return <GuidePage {...post} />;
      case 'form-page':
        return <PlainPage {...post} />;
      case 'legal-page':
        return <Article {...post} author={null} isDocumentLike />;
      case 'blog-listing':
        return <BlogListingPage {...(post as BlogsPage)} />;
      case 'news-listing':
        return <NewsListingPage {...(post as NewsPage)} />;
      default:
        /* void */
        break;
    }
  }
  switch (post.post_type) {
    case 'post':
    case 'blog':
      return <Article {...(post as ArticleProps)} />;
    default:
      /* void */
      break;
  }

  return <Page {...post} />;
};

export default Single;
