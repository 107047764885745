import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import * as Sentry from '@sentry/nextjs';
import { fetchEntry, fetchNavigations } from '@/lib/content-api';
import { ExtendedGetStaticPropsContext } from '@/lib/content-api/types';
import { isContentPreview } from '@/lib/content-api/utils';
import resolveEntryStaticData from '@/lib/content-api/entryDataResolver';
import getRevalidateValue from '@/lib/getRevalidateValue';

export { default } from './[...entry]';

// This also gets called at build time
export const getStaticProps = async (
  context: ExtendedGetStaticPropsContext,
) => {
  try {
    const { locale, preview, previewData } = context;
    const navigationsP = fetchNavigations({
      lang: locale,
      previewData,
    });

    /* TODO: Refactor resolveEntryStaticData's resolvers so that
     * they can be made to throw errors rather than deal with them and catch them here.
     * (Some of the resolvers already throw errors, but not all of them.)
     * Based on that either reduce revalidate time dynamically or display stale data.
     */
    const dataP = fetchEntry({
      slug: 'home',
      lang: locale,
      previewData,
    })
      .then((p) => resolveEntryStaticData(p, context))
      .catch((error) => {
        console.error('Error fetching home data', error);
        throw new Error('Stale while no data');
      });

    const stringTranslationsP = serverSideTranslations(locale || 'fi', [
      'frontoffice',
      'backoffice',
      'tilavahti',
      'hasura_errors',
    ]);

    const [navigations, data, stringTranslations] = await Promise.all([
      navigationsP,
      dataP,
      stringTranslationsP,
    ]);

    if (!data) {
      // Never consider frontpage as not found. Keep it stale
      throw new Error('Stale while no data');
    }

    const {
      meta = null,
      translations: entryTranslations = null,
      ...post
    } = data;

    return {
      props: {
        ...stringTranslations,
        data: post,
        metadata: {
          ...meta,
          url: post.url,
          canonical: meta?.canonical || post.url,
        },
        navigations,
        entryTranslations,
        isPreview: !!preview && isContentPreview(previewData),
        isAdminMode: !!preview && !isContentPreview(previewData),
      },
      // Re-generate the post at most once per second
      // if a request comes in
      revalidate: getRevalidateValue(),
    };
  } catch (error) {
    console.error('Error serving Index data', error);
    Sentry.captureException(error);
    // Keep the errored page stale
    throw new Error('Index page stale while no data');
  }
};
