import {
  GetStaticPaths,
  GetStaticPathsContext,
  GetStaticPathsResult,
} from 'next';
import * as Sentry from '@sentry/nextjs';
import dynamic from 'next/dynamic';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Post } from 'types';
import SinglePost from '@/components/entry-templates';
import { fetchNavigations, fetchEntries, fetchEntry } from '@/lib/content-api';
import { ExtendedGetStaticPropsContext } from '@/lib/content-api/types';
import resolveEntryStaticData from '@/lib/content-api/entryDataResolver';
import PublicLayout from '@/components/frontend/PublicLayout';
import { isContentPreview } from '@/lib/content-api/utils';
import getRevalidateValue from '@/lib/getRevalidateValue';

const Loader = dynamic(() => import('@/components/back-office/common/Loading'));
const ErrorPage = dynamic(
  () => import('@/components/entry-templates/ErrorPage'),
);

const Entry = ({ data }: { data?: Post }) => {
  if (!data) {
    return <Loader />;
  }
  try {
    return <SinglePost post={data} />;
  } catch (error) {
    console.error('Entry rendering failed: ', error);
    Sentry.captureException(error);
    return <ErrorPage status="500" subHeading="Something went quite wrong" />;
  }
};

Entry.layout = PublicLayout;

export default Entry;

type PathType = GetStaticPathsResult<{
  slug: string;
  entry: string[];
}>['paths'][0];

// Calculate site generation speed in case there's outstanding issues to be found. Only run during build
const NS_PER_SEC = 1e9;

export const getStaticPaths: GetStaticPaths = async ({
  locales = ['fi'],
}: GetStaticPathsContext) => {
  try {
    if (process.env.NODE_ENV === 'development') {
      console.info('Skipping getStaticPaths in development mode');

      return {
        paths: [],
        fallback: 'blocking',
      };
    }

    const start = process.hrtime();
    console.info('START PATHS FETCHING');

    let diff = process.hrtime(start);
    const checkpoint = process.hrtime();

    const siteData = await Promise.all(
      locales.map(async (locale) => {
        const entries = await fetchEntries({ lang: locale });

        return {
          entries: entries.filter(
            (entry) => !['/osta', '/osta/listing'].includes(entry),
          ),
          locale,
        };
      }),
    );

    diff = process.hrtime(checkpoint);
    console.info(
      `Entries took ${(diff[0] * NS_PER_SEC + diff[1]) / NS_PER_SEC} seconds`,
    );

    const paths: GetStaticPathsResult['paths'] = siteData.reduce<PathType[]>(
      (pathsAcc, { entries, locale }) =>
        entries.reduce((bacc, slug) => {
          if (!slug || slug === '/' || slug.includes('home')) {
            return bacc;
          }

          return [
            ...bacc,
            {
              params: {
                slug,
                entry: slug.split('/').filter(Boolean),
              },
              locale,
            },
          ];
        }, pathsAcc),
      [],
    );

    console.info('PATHS DONE');

    return {
      paths,
      fallback: 'blocking',
    };
  } catch (error) {
    console.error(`Error during getStaticPaths was caught: `, error);
    Sentry.captureException(error);

    return {
      paths: [],
      fallback: 'blocking',
    };
  }
};

export const getStaticProps = async (
  context: ExtendedGetStaticPropsContext,
) => {
  try {
    const { params: { entry } = {}, locale, preview, previewData } = context;
    const slug = (Array.isArray(entry) ? entry.join('/') : entry) || '';

    const navigationsP = fetchNavigations({
      lang: locale,
      previewData,
    });

    const isFailedSlug = slug === '[...entry]';
    if (isFailedSlug) {
      /* No idea why this sometimes happens. Maybe due to rest api having issues during build and then next can't bounce back for some reason.
       * Actually yeah, if pages/index.tsx build fails due to API error, it doesn't get build and falls back to [...entry] which doesn't find empty as a slug.
       */
      console.warn(
        'WARNING - For unknown reason slug was [...entry]. Resolved to home as a safety measure.',
      );
    }

    const dataP = fetchEntry({
      slug: isFailedSlug ? 'home' : slug,
      lang: locale,
      previewData,
    }).then((p) => resolveEntryStaticData(p, context));

    const stringTranslationsP = serverSideTranslations(locale || 'fi', [
      'frontoffice',
      'backoffice',
      'tilavahti',
    ]);

    const [navigations, data, stringTranslations] = await Promise.all([
      navigationsP,
      dataP,
      stringTranslationsP,
    ]);

    if (!data) {
      console.warn('No Entry data for: ', slug);
      if (!navigations) {
        // No post, no nav, API is down.
        throw new Error(
          `Entry page ${JSON.stringify(
            context.params?.entry,
          )} no navigation data`,
        );
      }

      // Actual not found
      return {
        props: {
          ...(stringTranslations || {}),
          entryTranslations: null,
          navigations: navigations || null,
        },
        notFound: true,
        revalidate: getRevalidateValue(480),
      };
    }

    const {
      meta = null,
      translations: entryTranslations = null,
      ...post
    } = data;

    return {
      props: {
        ...stringTranslations,
        data: post,
        metadata: {
          ...meta,
          url: post.url,
          canonical: meta?.canonical || post.url,
        },
        navigations,
        entryTranslations,
        isPreview: !!preview && isContentPreview(previewData),
        isAdminMode: !!preview && !isContentPreview(previewData),
      },
      revalidate: getRevalidateValue(),
    };
  } catch (error) {
    console.error(
      `Entry page ${JSON.stringify(context.params?.entry)} error`,
      error,
    );
    Sentry.captureException(error);
    throw new Error(
      `Entry page ${JSON.stringify(context.params?.entry)} stale while no data`,
    );
  }
};
